import { Box, makeStyles, Typography, Button } from "@material-ui/core"
import React from "react"
import "pure-react-carousel/dist/react-carousel.es.css"
import { Link } from "react-scroll"
import HeroSlider, { Slide, Nav } from "hero-slider"
import "./hero-section.css"

const useStyles = makeStyles(theme => ({
  dot: {
    backgroundColor: "#F26522",
    border: "0px",
    width: "50px",
    height: "4px",
    margin: "8px",
    "&:active": { backgroundColor: "#999" },
  },
  heroLogo: {
    width: "240px",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      marginBottom: "8px",
    },
  },
  heroContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "3",
    width: "100%",
    padding: theme.spacing(4),
    paddingTop: "88px",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 76.15%, rgba(0, 0, 0, 0.4) 78.62%, rgba(0, 0, 0, 0.4) 78.62%, rgba(0, 0, 0, 0.4) 81.44%, #000000 100%)",
    // [theme.breakpoints.down("sm")]: { height: "90vh" },
  },
  heroDescription: {
    maxWidth: "700px",
    margin: "32px 0",
    [theme.breakpoints.down("xs")]: { fontSize: "16px", margin: "24px 0" },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      height: "42px",
      fontSize: "14px",
    },
  },
}))

const HeroSection = ({ logo, description, buttonText, carosuelImages }) => {
  const classes = useStyles()
  return (
    <Box color="#999" style={{ paddingTop: "36px" }}>
      <HeroSlider
        slidingAnimation="left_to_right"
        orientation="horizontal"
        set
        initialSlide={1}
        style={{
          backgroundColor: "#000",
        }}
        settings={{
          slidingAnimation: "fade",
          slidingDuration: 500,
          slidingDelay: 0,
          shouldAutoplay: true,
          shouldDisplayButtons: false,
          autoplayDuration: 2000,
          height: "95vh",
        }}
      >
        <Box className={classes.heroContentWrapper}>
          <img src={logo} alt="logo" className={classes.heroLogo} />
          <Typography
            variant="h3"
            align="center"
            className={classes.heroDescription}
          >
            {description}
          </Typography>
          <Link to="section" offset={-120} smooth={true}>
            <Button variant="contained" className={classes.button}>
              {buttonText}
            </Button>
          </Link>
        </Box>
        {carosuelImages.map((item, index) => (
          <Slide
            index={index}
            key={index}
            background={{
              backgroundColor: "#8A8A8A",
              maskBackgroundBlendMode: "luminosity",
              backgroundImage: item.image,
              backgroundAnimation: "fade",
            }}
          />
        ))}
        <Nav />
      </HeroSlider>
    </Box>
  )
}

export default HeroSection
